import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5be1ddb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "complete" }
const _hoisted_2 = { class: "complete-container" }
const _hoisted_3 = { class: "tagline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "complete-image" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "complete-message mt-2" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_TextButton = _resolveComponent("TextButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("onboarding.completeTaglineText")), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("onboarding.completeTitleText")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          src: require('images/complete.svg'),
          alt: "complete image"
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", {
          innerHTML: _ctx.$t('onboarding.completeInfoLine'),
          class: "message"
        }, null, 8, _hoisted_8)
      ]),
      _createVNode(_component_FlatButton, {
        text: _ctx.$t('onboarding.completeCreditCard'),
        class: "complete-button w-full",
        enabled: _ctx.canAddPayment,
        onOnClick: _ctx.signUpStandard
      }, null, 8, ["text", "enabled", "onOnClick"]),
      _createVNode(_component_TextButton, {
        text: _ctx.$t('onboarding.completeAddButton'),
        class: "later-btn mt-2",
        onOnClick: _ctx.inviteMember
      }, null, 8, ["text", "onOnClick"]),
      _createVNode(_component_TextButton, {
        text: _ctx.$t('onboarding.completeLater'),
        class: "later-btn mt-2",
        onOnClick: _ctx.later
      }, null, 8, ["text", "onOnClick"])
    ])
  ]))
}