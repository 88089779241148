
// @ is an alias to /src
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import { storage } from "@/lib/storage";
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Complete",
  components: {
    FlatButton,
    TextButton,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      canAddPayment: false,
    });
    const signUpStandard = () => {
      router.push({ name: "PricingPlan" });
    };

    const inviteMember = () => {
      router.push({ name: "TeamMember" });
    };

    const later = () => {
      router.push({ name: "Dashboard" });
    };

    onMounted(() => {
      const localStorage = storage.getLocalStorage();
      const isCompanyCreating = localStorage.get("isCompanyCreating");
      if (isCompanyCreating) {
        state.canAddPayment = true;
      }
    });

    return {
      ...toRefs(state),
      signUpStandard,
      inviteMember,
      later,
    };
  },
  beforeRouteLeave(to, from, next) {
    const localStorage = storage.getLocalStorage();
    localStorage.remove("isCompanyCreating");
    next();
  },
});
